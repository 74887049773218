<template>
	<main>
		<ApplyBaseTemplate
			:interview-started="interviewStarted"
			:interview-finished="interviewFinished"
			:already-applied="alreadyApplied"
			:first-msg="firstMsg"
			:answered-msgs="answeredMsgs"
			:question-stats="questionStats"
			:guest-login="guestLogin"
			:err="err"
			:interview-mode="mode"
			:match-obj="match"
			:allow-cv-parsing="allowCvParsing"
			:hide-tabs="true"
			:audience-id="audienceId"
			:job-nr-or-id="jobNr"
			@update-allow-cv-parsing="allowCvParsing = $event"
			@update-match-obj="match = $event"
			@restart-interview="callRestartInterview"
			@interview-done="callFinishInterview"
		>
			<template #job-detail>
				<ContentContainer size="large">
					<JobDetail
						v-if="job"
						:job="job"
						:gnc-calculation="gncCalculation"
						single-job-view
						is-pwa
						:show-apply-button="false"
						@send-job="shareJob(job)"
						@send-gnc="calcGnc($event)"
						@go-to-jobsearch="openJobsearch"
						@show-map-dropdown="trackCommute('dropdown')"
						@show-map-image="trackCommute('image')"
						@show-usp="trackUSP"
					/>
				</ContentContainer>
			</template>
			<template v-if="!isInsidePageOpen" #heading>
				<div
					class="pb-2"
					:class="$isMobile.any ? 'shadow-lg' : 'border-solid border-b border-color-grey-light'"
				>
					<div class="flex mx-2 mt-2">
						<div
							v-if="job && job.company && job.company.logos && job.company.logos.medium"
							class="w-3/12 mr-3 flex items-center"
							style="max-width: 60px; max-height: 60px"
						>
							<NuxtImg
								:src="job.company.logos.medium"
								:alt="`Firmenlogo ${job.company.name}`"
								class="max-h-full max-w-full mx-auto"
							/>
						</div>
						<div
							class="flex flex-col justify-center"
							:class="
								job && job.company && job.company.logos && job.company.logos.medium
									? 'w-8/12'
									: 'w-11/12'
							"
						>
							<p class="mb-0 text-color-main truncate text-sm font-bold">
								{{ match && match.relation && match.relation.obj && match.relation.obj.name }}
							</p>
							<p class="mb-0 truncate text-sm">
								{{
									match &&
									match.relation &&
									match.relation.obj &&
									match.relation.obj.company &&
									match.relation.obj.company.name
								}}
							</p>
						</div>
						<div class="relative w-full flex items-center">
							<HokIcon
								name="icon:close-slim"
								:size="7"
								pointer
								class="absolute right-0"
								@click="$modal.show('preventClosing')"
							/>
						</div>
					</div>
				</div>
			</template>
		</ApplyBaseTemplate>
	</main>
</template>
<script lang="ts">
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import JobDetail from '@hokify/shared-components-nuxt3/lib/components/JobDetail.vue';
import { shareJob } from '@hokify/pwa-core-nuxt3/helpers/sharejob';
import ContentContainer from '@hokify/shared-components-nuxt3/lib/components/ContentContainer.vue';
import type {
	APIMatchRelation,
	APIObjectType,
	APITypeObjectId,
	IAPIJobForUser,
	IAPIMatchForJobSeeker,
	IAPIMatchForJobSeekerListEntry,
	IInterviewQuestion
} from '@hokify/common';
import hokifyApply, {
	ApplyMode,
	type IPWAInterviewQuestion
} from '@hokify/pwa-core-nuxt3/helpers/apply';
import { NotFoundError } from '@hokify/pwa-core-nuxt3/errors/NotFoundError';
import {
	isNotAuthenticatedError,
	NotAuthenticatedError
} from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import {
	defineNuxtComponent,
	definePageMeta,
	tryUseNuxtApp,
	useRoute,
	useSeoMeta,
	createError
} from '#imports';
import { useGncStore } from '@/stores/gnc';
import type { IStartInterviewResultDTO } from '@/stores/interview';
import ApplyBaseTemplate from '@/components/user/application/ApplyBaseTemplate.vue';
import { useInterviewStore } from '@/stores/interview';
import { useMatchesStore } from '@/stores/matches';
import { useRelationsStore } from '@/stores/relations';
import { useLoginStore } from '@/stores/login';

export default defineNuxtComponent({
	name: 'ApplyJob',
	setup() {
		definePageMeta({
			layout: 'apply',
			path: '/apply/:jobNr?',
			alias: '/apply-fb/:jobNr?',
			trackPageView: 'disable'
		});
	},
	components: {
		Tab,
		ApplyBaseTemplate,
		JobDetail,
		ContentContainer
	},
	data() {
		const gncCalculation = undefined as {} | undefined;
		const answeredMsgs: IPWAInterviewQuestion[] = [];
		const firstMsg = false as IInterviewQuestion | boolean | undefined;
		const err = null as null | Error;
		const match = undefined as undefined | IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker;
		const job = undefined as undefined | IAPIJobForUser;
		const interviewStarted = false;
		const interviewFinished = false;
		const alreadyApplied = false;
		const allowCvParsing = false;
		const restartedInterview = false;
		const guestLogin = false;
		const questionStats = {};
		const viewState: { mode: 'typeform' | 'match-overview' } = { mode: 'typeform' };

		return {
			gncCalculation,
			job,
			match,
			interviewStarted,
			interviewFinished,
			alreadyApplied,
			allowCvParsing,
			restartedInterview,
			err,
			guestLogin,
			firstMsg,
			answeredMsgs,
			questionStats,
			mode: ApplyMode.Apply,
			shareJob,
			viewState
		};
	},
	computed: {
		isInsidePageOpen() {
			return this.$page?.isOpen;
		},
		jobNr(): number | undefined {
			return this.job?.jobNr;
		},
		...mapStores(useGncStore, useInterviewStore, useLoginStore, useMatchesStore)
	},
	created() {
		const title = `${
			(this.job && this.job.name ? `Deine Bewerbung als ${this.job.name}` : 'Deine Bewerbung') +
			(this.job?.company.name ? ` bei ${this.job.company.name}` : '')
		} | hokify`;
		const description = `Versende jetzt deine Bewerbung mit wenigen Schritten für den Job ${this.job?.name || ''}
		  und übermittle sie direkt an ${this.job?.company.name || ' die Firma'}`;
		const image = this.job?.images?.large || '';
		const applyUrl = this.job?.webUrl?.replace('/job/', '/apply/');
		useSeoMeta({
			title,
			description,
			ogTitle: title,
			ogImage: image,
			ogDescription: description,
			ogUrl: applyUrl || ''
		});
	},
	async mounted() {
		await this.initializeData();

		if (this.job) {
			const queryParams =
				(window.location.search && new URLSearchParams(window.location.search)) || undefined;

			// tracking for remarketing
			const country = this.job?.location?.countryCode
				? this.job?.location.countryCode.replace(/\W/g, '').toUpperCase()
				: undefined;

			// set category via 1. prop (clickSource)  2. query param 3. default value
			this.$trackUserEvent?.('start_application', {
				matchId: this.match?._id,
				job: {
					name: this.job?.name,
					jobNr: this.job?.jobNr,
					_id: this.job?._id,
					jobfields: this.job?.fields.map(f => f.name),
					type: this.job?.type,
					priorize: this.job?.internal.trackingValue || 1,
					country,
					city: this.job?.location?.city || undefined
				},
				category: this.clickSource || queryParams?.get('cs') || 'direct-link', // cs .. click source
				relationId: this.job._id,
				item_type: 'job',
				is_logged_in: this.loginStore.loggedIn ? 1 : 0,
				application_type: this.job.selectedCandidate ? 'active-sourcing-ats' : 'regular'
			});
		}

		// show modal, before closing insidePage
		this.updateGoBackHandlerForCurrentPage();

		this.$trackUserEvent?.('apply', { jobNr: this.job?.jobNr });
	},
	methods: {
		async initializeData() {
			const { params } = useRoute();

			const interviewStore = useInterviewStore();
			const matchesStore = useMatchesStore();
			const relationsStore = useRelationsStore();
			let jobNr;

			if (params?.jobNr) {
				jobNr = params.jobNr;
			} else if (relationsStore.numberOrId) {
				jobNr = relationsStore.numberOrId.idOrNr;
			}

			let job: IAPIJobForUser | undefined;

			try {
				// special handling for "0000" - this shows an "active sourcing" job
				if (jobNr === '0000') {
					job = await relationsStore.getJob({
						jobNr
					});
				}
			} catch (err) {
				console.error('Special handlig - Error fetching active sourcing job', err);
			}

			try {
				// start interview
				const interviewRes: IStartInterviewResultDTO = await interviewStore.startInterview({
					relation: { type: 'job', obj: job?._id || parseInt(jobNr, 10) }
				});

				const matchObj = interviewRes?.success && interviewRes?.match;

				// save matchObj into store
				if (matchObj) {
					matchesStore.saveMatch(matchObj);
				}

				const {
					success,
					next,
					finish,
					cntOpenQuestions,
					totalQuestions,
					answeredQuestions,
					allowCvParsing
				} = interviewRes;

				let interviewStarted = false;
				let interviewFinished = false;
				let questionStats;

				if (success) {
					if (next) {
						interviewStarted = true;
					} else if (finish) {
						interviewFinished = true;
					}
					questionStats = {
						open: cntOpenQuestions,
						total: totalQuestions
					};
				}

				if (!job && matchObj && matchObj.relation.type === 'job' && matchObj.relation.obj) {
					job = matchObj.relation.obj;
				} else if (!job) {
					// we have no relation obj yet, retrieve relation obj separately
					job = await relationsStore.getJob({
						jobNr
					});
				}

				if (!job) {
					throw new NotFoundError('Job existiert nicht');
				}

				Object.assign(this, {
					job,
					match: matchObj || { relation: { type: 'job', obj: job } },
					interviewStarted,
					interviewFinished,
					answeredMsgs: answeredQuestions || [],
					firstMsg: next,
					questionStats,
					alreadyApplied: false,
					allowCvParsing,
					err: undefined
				});
			} catch (err: any) {
				const errorCode = err?.response?._data?.code;
				const errorStatus = err.response?._data.statusCode;

				// fetch the current job (checks also if live + gets title and stuff)
				if (!job) {
					try {
						job = await relationsStore.getJob({ jobNr });
					} catch (retrieveErr) {
						if (import.meta.client) {
							console.error(retrieveErr);
						}
						// ignore errors here
					}
				}

				if (!job) {
					throw createError({
						message: 'Diese Seite existiert nicht.',
						statusCode: 404,
						fatal: true
					});
				}

				// not logged in yet, login required
				if (isNotAuthenticatedError(err)) {
					const firstMsg = hokifyApply.startLoginOrSignUp();

					Object.assign(this, {
						job,
						guestLogin: !firstMsg,
						interviewStarted: true,
						interviewFinished: false,
						firstMsg,
						answeredMsgs: [],
						match: { relation: { type: 'job', obj: job } },
						// set a default value, to be sure stats bar is always shown
						questionStats: {
							open: 100,
							total: 100
						},
						alreadyApplied: false,
						err
					});

					return;
				}

				// already applied
				if (errorCode === 'ALREADY_APPLIED') {
					Object.assign(this, {
						job,
						interviewStarted: false,
						interviewFinished: true,
						answeredMsgs: [],
						match: { relation: { type: 'job', obj: job } },
						alreadyApplied: true,
						err
					});

					return;
				}

				// generic / other error
				if (import.meta.client) {
					console.error('start-interview ERROR', err?.response, {
						errorStatus,
						errorCode,
						code: err.response._data.code,
						statusCode: err.response._data.statusCode,
						instanceOfNotAuthenticatedError: err instanceof NotAuthenticatedError,
						typeofErrorNow: typeof err,
						isNotAuthenticatedError: isNotAuthenticatedError(err)
					});
				}

				const nuxtApp = tryUseNuxtApp();
				if (nuxtApp?.$sentry) {
					nuxtApp.$sentry.withScope(scope => {
						scope.setExtra('errorHandler', '/apply/[jobNr]');
						if (err instanceof Error) {
							nuxtApp.$sentry.captureException(err);
						} else {
							scope.setExtra('err', err);
							nuxtApp.$sentry.captureMessage(
								`errorHandler${typeof err.toString === 'function' ? ` ${err.toString()}` : ''}`
							);
						}
					});
				}

				if (errorCode === 'JOB_OFFLINE') {
					this.err = err;

					return;
				}

				throw createError({
					statusCode: errorStatus,
					message: 'Eine Bewerbung für diesen Job ist momentan nicht möglich.',
					fatal: true
				});
			}
		},
		async calcGnc(gnc) {
			try {
				this.gncCalculation = await this.gncStore.sendGnc({
					...gnc,
					locationUrl: 'job-detail-inside-application'
				});
				this.$trackUserEvent?.('start_calculation', {});
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		openJobsearch() {
			this.$router.push('/pwa');
			this.$trackUserEvent?.('click_redirect', { url: '/pwa' });
		},
		trackCommute(type: 'dropdown' | 'image') {
			this.$trackUserEvent?.('click_commute_element', {
				pageElement: type
			});
		},
		trackUSP() {
			this.$trackUserEvent?.('click_education', {});
		},
		callRestartInterview() {
			if (this.job) {
				const relation: APIMatchRelation = {
					type: 'job',
					obj: this.job
				};
				this.restartInterview(relation);
			}
		},
		callFinishInterview() {
			if (this.job) {
				const relation: APIMatchRelation = {
					type: 'job',
					obj: this.job
				};
				this.finishInterview(relation);
			}
		},
		async restartInterview(relation: APIMatchRelation) {
			try {
				const interviewRes: IStartInterviewResultDTO = await this.interviewStore.startInterview({
					relation,
					resetSkippedQuestions: true,
					audienceId: this.audienceId
				});

				if (interviewRes) {
					const {
						success,
						next,
						cntOpenQuestions,
						totalQuestions,
						answeredQuestions,
						allowCvParsing
					} = interviewRes;
					if (success) {
						this.questionStats = {
							open: cntOpenQuestions,
							total: totalQuestions
						};
					}
					this.answeredMsgs = answeredQuestions || [];
					this.firstMsg = next;

					// reset all params to redirect to typeform again
					this.interviewFinished = false;
					this.interviewStarted = true;
					this.alreadyApplied = false;
					this.allowCvParsing = allowCvParsing;

					// reset view mode
					this.viewState.mode = 'typeform';

					// additionally remember, we have restarted interview
					this.restartedInterview = true;
				}
			} catch (err: any) {
				console.warn(err);
			}
		},
		async finishInterview(relation: APIMatchRelation) {
			// if the interview is already finished, there is no need anymore to block leaving the interview process via a modal
			this.updateGoBackHandlerForCurrentPage(true);

			// after finishing the interview, make sure you have a proper match object
			// by now this might be only a "fake" match obj with job/company inside, so you can start the interview
			// but at this point (for showing the matchoverview) you need a proper match object, to show matchquestions
			// also get new matchObj if interview was restarted, to make sure all questions are up to date
			// grab correct match obj
			if (!this.match?._id || this.restartedInterview) {
				this.match = this.matchesStore.getMatchByRelation(relation);

				if (!this.match) {
					const err = new Error(`match not found for relation ${relation?.obj?._id}`);

					// this can happen for active sourcing jobs for example
					if (relation) {
						console.error(err);
						const interviewRes: IStartInterviewResultDTO = await this.interviewStore.startInterview(
							{
								relation,
								audienceId: this.audienceId
							}
						);

						const matchObj = interviewRes && interviewRes.success && interviewRes.match;
						if (matchObj) {
							this.match = matchObj;
						} else {
							this.match = { relation } as IAPIMatchForJobSeeker;
						}
					} else {
						throw err;
					}
				}
			} else {
				this.match = this.matchesStore.getMatchByRelation(relation);
			}

			this.interviewFinished = true;
			this.interviewStarted = false;

			// ui components have a different style for match-overview
			this.viewState.mode = 'match-overview';

			if (import.meta.client) {
				this.$nextTick(() => {
					this.$page.scrollToTop();
				});
			}
		},
		updateGoBackHandlerForCurrentPage(forceClosing = false) {
			// show modal, before closing insidePage
			this.$page.setGoBackHandlerForCurrentPage(() => {
				if (forceClosing) {
					return false;
				}
				this.$modal.show('preventClosing');
				this.$trackUserEvent?.('application_cancel_show_modal', {});
				return true;
			});
		}
	},
	props: {
		clickSource: { type: String, required: false, default: '' },
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: ''
		}
	}
});
</script>
